import { createClient, SupabaseClient } from "@supabase/supabase-js";
import { randomUUID } from "crypto";
import { definitions } from "../types/supabase";
import { sequentially } from "./sequentially";
import { getEnvVar } from "./getEnvVar";

type MaybePromisify<T> = T | Promise<T>;
type SupabaseOption = [
  "from" | "method" | "args" | "eq" | "order" | "limit",
  string | object | Array<object>,
];

class InMemoryStorage {
  _storage: Map<string, string>;

  constructor() {
    this._storage = new Map();
  }

  setItem(key: string, value: string) {
    this._storage.set(key, value);
  }

  getItem(key: string): MaybePromisify<string | null> {
    // we return null if the key is undefined

    return this._storage.has(key) ? this._storage.get(key) || null : null;
  }

  removeItem(key: string) {
    this._storage.delete(key);
  }
}

function canAccessLocalStorage() {
  try {
    const testKey = randomUUID();
    localStorage.setItem(testKey, testKey);
    localStorage.removeItem(testKey);
    return true;
  } catch (e) {
    return false;
  }
}

const returnOnSelect = {
  global: { headers: { Prefer: "return=representation" } },
};

const setLocalStorageToInMemory = {
  localStorage: new InMemoryStorage(),
};

export class DataNotFoundError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "DataNotFoundError";
  }
}

export class XmsSupabase {
  supabase: SupabaseClient;
  options: any[] = [];

  constructor(supabaseUrl: string, supabaseKey: string) {
    if (supabaseUrl === "none") {
      this.supabase = {} as any;
      return this;
    }

    if (typeof window !== "undefined") {
      // client side -- some browsers disallow access to localStorage, especially in incongnito mode
      // once we are serving everything from Next (and not from instapage/inject modal stuff), we should be able to remove this
      if (!canAccessLocalStorage()) {
        this.supabase = createClient(supabaseUrl, supabaseKey, {
          auth: { persistSession: false },
          ...returnOnSelect,
          ...setLocalStorageToInMemory,
        });
      } else {
        this.supabase = createClient(supabaseUrl, supabaseKey, {
          ...returnOnSelect,
        });
      }
    } else {
      // server side
      this.supabase = createClient(supabaseUrl, supabaseKey, {
        ...returnOnSelect,
      });
    }
  }

  asAdmin() {
    this.options = [];
    this.options.push(["as", "admin"]);
    return this;
  }

  runningAsAdmin() {
    return this.options.some((x) => x[0] == "as" && x[1] == "admin");
  }

  async executeOptions(options: any[]) {
    const from = options.find(
      (option: SupabaseOption) => option[0] == "from"
    )[1];
    const method = options.find(
      (option: SupabaseOption) => option[0] == "method"
    )[1];
    const args = options.find(
      (option: SupabaseOption) => option[0] == "args"
    )[1];
    const filters =
      options.filter((option: SupabaseOption) =>
        ["filter", "eq", "not", "ilike", "is"].includes(option[0])
      ) || [];
    const order = options.filter(
      (option: SupabaseOption) => option[0] == "order"
    );
    const limit = options.filter(
      (option: SupabaseOption) => option[0] == "limit"
    );

    let supabaseQuery: any;

    if (method === "upsertAll") {
      supabaseQuery = this.from(from).upsertAll(args as Array<object>);
      return await supabaseQuery;
    }

    if (args) {
      supabaseQuery = (this.supabase.from(from) as any)[method](args);
    } else {
      supabaseQuery = (this.supabase.from(from) as any)[method]();
    }

    filters.forEach(
      (filter: [string, { column: string; args: any; operator?: string }]) => {
        const [operand, columnAndArgs] = filter;

        switch (operand) {
          case "eq":
            supabaseQuery = supabaseQuery.eq(
              columnAndArgs.column,
              columnAndArgs.args
            );
            break;
          case "is":
            supabaseQuery = supabaseQuery.is(
              columnAndArgs.column,
              columnAndArgs.args
            );
            break;
          case "in":
            supabaseQuery = supabaseQuery.in(
              columnAndArgs.column,
              columnAndArgs.args
            );
            break;
          case "filter":
            supabaseQuery = supabaseQuery.filter(
              columnAndArgs.column,
              columnAndArgs.operator,
              columnAndArgs.args
            );
            break;
          case "not":
            supabaseQuery = supabaseQuery.not(
              columnAndArgs.column,
              columnAndArgs.operator,
              columnAndArgs.args
            );
            break;
          case "ilike":
            supabaseQuery = supabaseQuery.ilike(
              columnAndArgs.column,
              columnAndArgs.args
            );
            break;
          case "gte":
            supabaseQuery = supabaseQuery.gte(
              columnAndArgs.column,
              columnAndArgs.args
            );
            break;
          case "lte":
            supabaseQuery = supabaseQuery.lte(
              columnAndArgs.column,
              columnAndArgs.args
            );
            break;
          default:
            console.log("no operand found for: ", operand);
        }
      }
    );

    if (order.length > 0) {
      const [_order, orderByColumn, ascending] = order[0];
      supabaseQuery = supabaseQuery.order(orderByColumn, ascending);
    }

    if (limit.length > 0) {
      const [_limit, limitCount] = limit[0];
      supabaseQuery = supabaseQuery.limit(limitCount);
    }

    const response = await supabaseQuery;
    return response;
  }

  async endAsAdmin() {
    if (typeof window === "undefined") {
      return this.executeOptions(this.options);
    }

    const response = await fetch("/api/supabase/", {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        options: this.options,
      }),
    });

    const json = await response.json();
    return json.rawResponse;
  }

  select(args: any) {
    if (this.runningAsAdmin()) {
      this.options.push(["method", "select"]);
      this.options.push(["args", args]);
    } else {
      console.log("select not currently supported");
    }

    return this;
  }

  eq(columnName: string, args: any) {
    if (this.runningAsAdmin()) {
      this.options.push(["eq", { column: columnName, args }]);
    } else {
      console.log("eq not currently supported");
    }

    return this;
  }

  is(columnName: string, args: any) {
    if (this.runningAsAdmin()) {
      this.options.push(["is", { column: columnName, args }]);
    } else {
      console.log("is not currently supported");
    }

    return this;
  }

  in(columnName: string, args: Array<string>) {
    if (this.runningAsAdmin()) {
      this.options.push(["in", { column: columnName, args }]);
    } else {
      console.log("in not currently supported");
    }

    return this;
  }

  gte(columnName: string, args: string) {
    if (this.runningAsAdmin()) {
      this.options.push(["gte", { column: columnName, args }]);
    } else {
      console.log("gte not currently supported");
    }

    return this;
  }

  lte(columnName: string, args: string) {
    if (this.runningAsAdmin()) {
      this.options.push(["lte", { column: columnName, args }]);
    } else {
      console.log("lte not currently supported");
    }

    return this;
  }

  filter(column: string, operator: string, args: any) {
    if (this.runningAsAdmin()) {
      this.options.push(["filter", { column, operator, args }]);
    } else {
      console.log("filter not currently supported");
    }

    return this;
  }

  mustNotHaveTags(tags: Array<{ [key: string]: string }>) {
    tags.map((tag) => {
      // Tag must be wrapped in an array for PostgREST to filter properly

      this.filter("tags", "not.cs", JSON.stringify([tag]));
    });

    return this;
  }

  mustHaveTags(tags: Array<{ [key: string]: string }>) {
    tags.map((tag) => {
      // Tag must be wrapped in an array for PostgREST to filter properly

      this.filter("tags", "cs", JSON.stringify([tag]));
    });

    return this;
  }

  ilike(columnName: string, args: any) {
    if (this.runningAsAdmin()) {
      this.options.push(["ilike", { column: columnName, args }]);
    } else {
      console.log("ilike not currently supported");
    }

    return this;
  }

  insert(args: any) {
    if (this.runningAsAdmin()) {
      this.options.push(["method", "insert"]);
      this.options.push(["args", args]);
    } else {
      console.log("insert not currently supported");
    }

    return this;
  }

  order(args: any, ascending?: any) {
    if (this.runningAsAdmin()) {
      this.options.push(["order", args, ascending || { ascending: true }]);
    } else {
      console.log("not currently supported");
    }

    return this;
  }

  limit(args: any) {
    if (this.runningAsAdmin()) {
      this.options.push(["limit", args]);
    } else {
      console.log("order not currently supported");
    }

    return this;
  }

  update(args: any) {
    if (this.runningAsAdmin()) {
      this.options.push(["method", "update"]);
      this.options.push(["args", args]);
    } else {
      console.log("update not currently supported");
    }

    return this;
  }

  delete() {
    if (this.runningAsAdmin()) {
      this.options.push(["method", "delete"]);
      this.options.push(["args", undefined]);
    } else {
      console.log("delete not currently supported");
    }

    return this;
  }

  not(column: string, operator: string, args: any) {
    if (this.runningAsAdmin()) {
      this.options.push(["not", { column, operator, args }]);
    } else {
      console.log("not not currently supported");
    }

    return this;
  }

  getDataFromAutoGenView<T>(tableName: string, response: any): Array<T> {
    console.log("This is beta and the API may change.");

    if (response instanceof XmsSupabase) {
      console.warn("You forgot to call .endAsAdmin()");
      throw new Error("You forgot to call .endAsAdmin()");
    }

    if (response.error) {
      throw response.error;
    }

    const data = response.data.map((x: any) => {
      const object: { [tableName: string]: { [columnName: string]: unknown } } =
        {};

      const knownTableNames = [
        "resources",
        "brands_resources",
        "brands",
        "brands_configurations",
        "lessons",
        "lesson_plans",
        "products",
      ];

      Object.keys(x).forEach((key) => {
        knownTableNames.forEach((knownTableName) => {
          if (key.startsWith(`${knownTableName}_`)) {
            const columnName = key.replace(`${knownTableName}_`, "");

            if (knownTableName === tableName) {
              // if it is the original table we are querying, then we don't want to nest it
              object[columnName] = x[key];
            } else {
              object[knownTableName] = object[knownTableName] || {};
              object[knownTableName][columnName] = x[key];
            }
          }
        });
      });

      return object;
    });

    return data;
  }

  getData<T>(response: any): Array<T> {
    if (response instanceof XmsSupabase) {
      console.warn("You forgot to call .endAsAdmin()");
      throw new Error("You forgot to call .endAsAdmin()");
    }

    if (response.error) {
      throw response.error;
    }

    return response.data;
  }

  ensureOne<T>(response: any, errorMessage?: string): T {
    if (response instanceof XmsSupabase) {
      console.log(new Error().stack);
      console.warn("You forgot to call .endAsAdmin()");
      throw new Error("You forgot to call .endAsAdmin()");
    }

    const data = this.getData(response);

    if (data.length === 0) {
      throw new DataNotFoundError("No data found: " + (errorMessage || ""));
    }

    return data[0] as T;
  }

  hasAny(response: any) {
    return this.getData(response).length > 0;
  }

  from(table: string) {
    this.options.push(["from", table]);
    return this;
  }

  async upsertAll(data: any[], options?: { onError: "continue" }) {
    const [operator, argument] = this.options.pop();

    return await sequentially(data, async (item: any) => {
      return await this.performUpsert(item, operator, argument, options);
    });
  }

  // metadata and order don't work with upsert currently, just FYI
  async upsert<T>(data: object, options?: { onError: "continue" }): Promise<T> {
    const [operator, argument] = this.options.pop();

    return this.performUpsert(data, operator, argument, options);
  }

  adminUpsert(data: object) {
    this.options.push(["method", "upsertAll"]);
    this.options.push(["args", data]);
    return this;
  }

  async performUpsert(
    data: object,
    operator: any,
    argument: any,
    options?: { onError: "continue" }
  ) {
    try {
      const tableScopedSupabase = (this.supabase as any)[operator](argument);

      let response;
      if ((data as any).id) {
        response = await this.addConditions(
          tableScopedSupabase.select("*").eq("id", (data as any).id),
          {}
        );
      } else {
        response = await this.addConditions(
          tableScopedSupabase.select("*"),
          data
        );
      }

      if (response.data && response.data.length == 0) {
        const insertResponse = await tableScopedSupabase.insert(data);

        if (insertResponse.error) {
          throw insertResponse.error;
        }

        return insertResponse.data[0];
      } else {
        if (!response.data) {
          console.log("update error:", response, data);

          throw new Error("update error");
        }

        const updateResponse = await tableScopedSupabase
          .update({
            ...data,
          })
          .match({ id: response.data[0].id });
        if (updateResponse.error) {
          console.log("update response error", data, response);
          throw updateResponse.error;
        }

        if (updateResponse.data.length == 0) {
          throw new Error("No data returned from update");
        }

        return updateResponse.data[0];
      }
    } catch (error) {
      if (options?.onError === "continue") {
        console.log(error, "continuing...");
      } else {
        console.log("upsert error:", error, data);
        throw error;
      }
    }
  }

  addConditions(supabase: any, data: object) {
    Object.keys(data).forEach((key) => {
      if (["metadata", "order", "required_data"].includes(key)) {
        // nop
      } else {
        supabase = supabase.eq(key, (data as any)[key]);
      }
    });

    return supabase.select("*");
  }

  async findOrCreateUser({
    email,
    password,
  }: {
    email: string;
    password?: string;
  }): Promise<any> {
    let supabaseUser;

    const potentialUsers = this.getData(
      await this.supabase
        .from("users")
        .select("*")
        .eq("email", email.toLocaleLowerCase())
    );

    if (potentialUsers.length > 0) {
      supabaseUser = potentialUsers[0];
    } else {
      const supabaseUserResponse = await this.supabase.auth.signUp({
        email,
        password: password || randomUUID(),
      });
      if (supabaseUserResponse.error) {
        throw supabaseUserResponse.error;
      }
      supabaseUser = supabaseUserResponse.data.user;

      await this.supabase.auth.signOut(); // sign out, go back to normal service key

      await this.supabase
        .from("profiles")
        .insert({ user_id: supabaseUser?.id });
    }

    return supabaseUser;
  }

  async createEntity<T>(tableName: string, data: object) {
    return this.ensureOne<T>(await this.supabase.from(tableName).insert(data));
  }

  async createOrder(
    data: Partial<definitions["orders"]>
  ): Promise<definitions["orders"]> {
    return this.createEntity("orders", data);
  }

  async createOrderLineItem(
    data: Partial<definitions["order_line_items"]>
  ): Promise<definitions["order_line_items"]> {
    return this.createEntity("order_line_items", data);
  }

  async createProduct(
    data: Partial<definitions["products"]>
  ): Promise<definitions["products"]> {
    return this.createEntity("products", data);
  }

  async createPackage(
    data: Partial<definitions["packages"]>
  ): Promise<definitions["packages"]> {
    return this.createEntity("packages", data);
  }

  async createPackageProduct(
    data: Partial<definitions["packages_products"]>
  ): Promise<definitions["packages_products"]> {
    return this.createEntity("packages_products", data);
  }

  async createOffer(
    data: Partial<definitions["offers"]>
  ): Promise<definitions["offers"]> {
    return this.createEntity("offers", data);
  }

  async createBrand(
    data: Partial<definitions["brands"]>
  ): Promise<definitions["brands"]> {
    return this.createEntity("brands", data);
  }
}

const isServer = () => {
  return typeof window === "undefined" || process.env.NODE_ENV === "test";
};

const xmsSupabase = new XmsSupabase(
  isServer() ? getEnvVar("XMS_SUPABASE_API_URL") : "none",
  isServer() ? getEnvVar("XMS_SUPABASE_SECRET_KEY") : "none"
);

export default xmsSupabase;

export const clientSideSupabase = new XmsSupabase(
  getEnvVar("NEXT_PUBLIC_SUPABASE_URL"),
  getEnvVar("NEXT_PUBLIC_SUPABASE_KEY")
);
