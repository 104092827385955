export const CurrentEnvironment = (): Environment => {
  const environment = process.env.NEXT_PUBLIC_ENV || process.env.ENV || "";

  const onServer = typeof window === "undefined";

  if (onServer && process.env.NEXT_PUBLIC_ENV !== process.env.ENV) {
    throw new Error("ENV and NEXT_PUBLIC_ENV must match");
  }

  if (["local", "stg", "prd"].indexOf(environment) > -1) {
    return environment as Environment;
  }

  throw new Error("Unknown environment: " + environment);
};

export type Environment = "local" | "stg" | "prd";
