/* eslint-disable max-len */

import { CurrentEnvironment } from "./CurrentEnvironment";

// This forces an ENV of local to always point to local supabase.
// This should prevent any accidental writes to production, or at least
// we know the developer intentionally did it and we can hold them accountable :fire:

export const getEnvVar = (key: string) => {
  if (CurrentEnvironment() === "local") {
    // These are the values for the local supabase instance
    // They are not secret as every supabase instance on the same version will use them
    // If we update supabase versions, these may need to change (but the whole team
    // should be on the same version of supabase, anyway, so this may be a good forcing function)

    const value = {
      XMS_SUPABASE_API_URL: "http://localhost:54321",
      XMS_SUPABASE_SECRET_KEY:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZS1kZW1vIiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImV4cCI6MTk4MzgxMjk5Nn0.EGIM96RAZx35lJzdJsyH-qQwv8Hdp7fsn3W0YpN81IU",
      NEXT_PUBLIC_SUPABASE_URL: "http://localhost:54321",
      NEXT_PUBLIC_SUPABASE_KEY:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZS1kZW1vIiwicm9sZSI6ImFub24iLCJleHAiOjE5ODM4MTI5OTZ9.CRXP1A7WOeoJeXxjNni43kdQwgnWNReilDMblYTn_I0",
    }[key];

    if (!value) {
      throw new Error("Missing env variable: " + key);
    }

    return value;
  }

  // these have to be written this way so that next will replace them with the actual values
  // that means we can't just look up process.env[key] because Next won't expose those env vars
  const value = {
    XMS_SUPABASE_API_URL: process.env.XMS_SUPABASE_API_URL,
    XMS_SUPABASE_SECRET_KEY: process.env.XMS_SUPABASE_SECRET_KEY,
    NEXT_PUBLIC_SUPABASE_URL: process.env.NEXT_PUBLIC_SUPABASE_URL,
    NEXT_PUBLIC_SUPABASE_KEY: process.env.NEXT_PUBLIC_SUPABASE_KEY,
  }[key];

  if (!value) {
    throw new Error(
      "Missing env variable or explicit exposure to the front end in getEnvVars: " +
        key
    );
  }

  return value;
};

/* eslint-enable max-len */
