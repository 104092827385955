// This method allows us to run a list of async functions sequentially - that is, they will NOT fire all at once.
// This is useful for when we want to avoid a race condition. For example, we have application-level logic
// to allow for upserts. When a large dataset is done with `await Proise.all(data.map((item) => upsert(item)))`,
// there is no determinism in when those fire. The issue is that we check to see if we should update or insert a record
// in 1 query, then do the actual update/insert in a second query. There is enough time between those two queries
// that a parallel process could successfully insert a record, then when we tried to insert a record with the same data,
// it would raise an error at the DB level.

export const sequentially = async (array: any[], callback: any) => {
  const results = [];
  for (const item of array) {
    results.push(await callback(item));
  }

  return results;
};

// The following function allows us to execute a list of async functions in parallel, but with a limit on the number of
// functions running in parallel at any one time.

const arrayBatch = <T>(array: Array<T>, batchSize: number) => {
  const batchedArray: Array<Array<T>> = [];

  for (let i = 0; i < array.length; i += batchSize) {
    const batch = array.slice(i, i + batchSize);
    batchedArray.push(batch);
  }

  return batchedArray;
};

export const batched = async <T>(
  array: T[],
  callback: (arg: T) => void,
  batchSize: number = 20
) => {
  const results = [];
  const batches = arrayBatch(array, batchSize);

  for (const batch of batches) {
    const batchResults = await Promise.all(batch.map(callback));
    results.push(...batchResults);
  }

  return results;
};
